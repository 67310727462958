<template>
	<div>
		<!-- Filters -->
		<criticalities-list-filters
			v-if="isOptionsLoaded"
			:options="options"
			:states-filter.sync="statesFilter"
			:criticality-levels-filter.sync="criticalityLevelsFilter"
			:month-filter.sync="monthFilter"
			:year-filter.sync="yearFilter"
		/>

		<!-- Table Container Card -->
		<b-card
			no-body
			class="mb-0"
		>
			<div class="m-2">
				<!-- Table Top -->
				<b-row>
					<!-- Per Page -->
					<b-col
						cols="12"
						md="4"
						class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
					>
						<label>Mostrar</label>
						<v-select
							v-model="perPage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label>registros</label>
					</b-col>

					<!-- Search -->
					<b-col
						cols="12"
						md="6"
						class="ml-auto"
					>
						<div class="d-flex">
							<b-form-input
								v-model="searchQuery"
								class="d-inline-block mr-1"
								placeholder="Buscar proveedor"
								trim
							/>
							<!-- 	<b-button variant="success">
								<span class="text-nowrap">Importar Excel</span>
							</b-button>-->
						</div>
					</b-col>
				</b-row>
			</div>

			<b-table
				ref="refPerformanceListTable"
				class="position-relative table-performances"
				:style="'height: inherit'"
				:items="fetchPerformances"
				responsive
				:fields="tableColumns"
				primary-key="id2"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No se encontraron registros coincidentes"
				:sort-desc.sync="isSortDirDesc"
			>
				<template #table-busy>
					<div class="text-center text-primary my-2">
						<b-spinner class="align-middle mb-1"></b-spinner>
						<div>
							<b>CARGANDO</b>
						</div>
					</div>
				</template>

				<template #head(security)="data">
					<span v-html="data.label"></span>
				</template>
				<template #head(productivity)="data">
					<span v-html="data.label"></span>
				</template>
				<template #head(labor_risk)="data">
					<span v-html="data.label"></span>
				</template>
				<template #head(colpa)="data">
					<span v-html="data.label"></span>
				</template>
				<template #head(financial_risk)="data">
					<span v-html="data.label"></span>
				</template>
				<template #head(rotation)="data">
					<span v-html="data.label"></span>
				</template>
				<template #head(penalities)="data">
					<span v-html="data.label"></span>
				</template>

				<template #head(calibration)="data">
					<span v-html="data.label"></span>
				</template>
				<!-- 	<template #head(performance_name)="data">
					<span v-html="data.label"></span>
				</template>-->

				<template #cell(cycle_date)="data">
					<b-link
						v-if="!data.item.locked"
						:to="{ name: 'performances-evaluate', params: { id: data.item.id } }"
						class="font-weight-bold d-block text-nowrap"
					>
						<b-badge variant="light-info">{{ data.item.month }}</b-badge>
					</b-link>
					<b-badge
						v-else
						variant="light-secondary"
					>
						<feather-icon icon="LockIcon" />
						{{ data.item.month }}
					</b-badge>
				</template>

				<template #cell(state_name)="data">
					<b-badge :variant="data.item.state_color">{{ data.item.state_name }}</b-badge>
				</template>

				<template #cell(provider_name)="data">
					<b-link
						:to="{ name: 'providers-edit', params: { id: data.item.provider_id } }"
						class="font-weight-bold d-block text-nowrap"
					>{{ data.item.provider_name }}</b-link>
				</template>

				<template #cell(security)="data">
					<div
						class="text-nowrap"
					>{{ data.item.security ? parseFloat(data.item.security).toFixed(2) : '-' }}</div>
				</template>

				<template #cell(productivity)="data">
					<div
						class="text-nowrap"
					>{{ data.item.productivity ? parseFloat(data.item.productivity).toFixed(2) : '-' }}</div>
				</template>

				<template #cell(labor_risk)="data">
					<div class="text-nowrap">{{ data.item.labor_risk || '-'}}</div>
				</template>

				<template #cell(colpa)="data">
					<div class="text-nowrap">{{ data.item.colpa || '-'}}</div>
				</template>

				<template #cell(financial_risk)="data">
					<div class="text-nowrap">{{ data.item.financial_risk || '-'}}</div>
				</template>

				<template #cell(rotation)="data">
					<div class="text-nowrap">{{ data.item.rotation || '-'}}</div>
				</template>

				<template #cell(penalities)="data">
					<div class="text-nowrap">{{ data.item.penalities || '-'}}</div>
				</template>

				<template #cell(calibration)="data">
					<div
						class="text-nowrap"
					>{{ data.item.calibration ? 'SÍ' : data.item.state_id === 2 && !data.item.calibration ? 'NO' : '-'}}</div>
				</template>

				<template #cell(performance_name)="data">
					<b-badge :variant="data.item.performance_color">{{ data.item.performance_name || '-' }}</b-badge>
				</template>

				<!-- <template #cell(total_score)="data">
					<div
						class="text-nowrap"
						:class="{ 'text-primary font-weight-bold': data.item.total_score}"
					>{{ data.item.total_score || '-'}}</div>
				</template>

				<template #cell(criticality_name)="data">
					<b-badge :variant="data.item.criticality_color">{{ data.item.criticality_name || '-' }}</b-badge>
				</template>-->

				<template #cell(actions)="data">
					<b-dropdown
						variant="link"
						no-caret
						:right="$store.state.appConfig.isRTL"
					>
						<template #button-content>
							<feather-icon
								icon="MoreVerticalIcon"
								size="16"
								class="align-middle text-body"
							/>
						</template>

						<b-dropdown-item :to="{ name: 'performances-evaluate', params: { id: data.item.id } }">
							<feather-icon icon="EditIcon" />
							<span class="align-middle ml-50">Evaluar</span>
						</b-dropdown-item>

						<b-dropdown-item @click="openCommentModal(data.item)">
							<feather-icon icon="MessageSquareIcon" />
							<span class="align-middle ml-50">Comentar</span>
						</b-dropdown-item>
					</b-dropdown>
				</template>
			</b-table>

			<div class="mx-2 mb-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span
							class="text-muted"
						>Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
					</b-col>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="currentPage"
							:total-rows="totalPerformances"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>

		<b-modal
			ref="refCommentModal"
			title-tag="div"
			header-bg-variant="primary"
			header-text-variant="white"
			cancel-title="Cerrar"
			centered
			title="COMENTARIOS POR DESEMPEÑO REDUCIDO"
			no-close-on-backdrop
			no-close-on-esc
			hide-header-close
			hide-footer
			size="lg"
		>
			<validation-observer
				#default="{ handleSubmit }"
				ref="refCommentForm"
			>
				<b-form @submit.prevent="handleSubmit(saveCommentModal)">
					<b-card
						footer-tag="footer"
						class="mb-0"
					>
						<b-row>
							<b-col
								cols="12"
								class="mb-2"
							>
								<label
									for="security"
									class="text-primary"
								>
									<strong>Indicador Principal - Seguridad</strong>
								</label>
								<div
									class="editor-container"
									:style="{ minHeight: editorHeight + 'px' }"
								>
									<quill-editor
										id="security"
										v-model="formData.security_comment"
										:options="snowOption"
										placeholder="Deje comentario aquí ..."
									/>
								</div>
							</b-col>
							<b-col
								cols="12"
								class="mb-2"
							>
								<label
									for="productivity"
									class="text-primary"
								>
									<strong>Indicador Principal - Productividad</strong>
								</label>
								<b-form-group>
									<quill-editor
										id="productivity"
										v-model="formData.productivity_comment"
										:options="snowOption"
										placeholder="Deje comentario aquí ..."
									/>
								</b-form-group>
							</b-col>
							<b-col
								cols="12"
								class="mb-2"
							>
								<label
									for="labor"
									class="text-primary"
								>
									<strong>Indicador de Calibración - Laboral</strong>
								</label>
								<b-form-group>
									<quill-editor
										id="labor"
										v-model="formData.labor_comment"
										:options="snowOption"
										placeholder="Deje comentario aquí ..."
									/>
								</b-form-group>
							</b-col>
							<b-col
								cols="12"
								class="mb-2"
							>
								<label
									for="colpa"
									class="text-primary"
								>
									<strong>Indicador de Calibración - Colpa</strong>
								</label>
								<b-form-group>
									<quill-editor
										id="colpa"
										v-model="formData.colpa_comment"
										:options="snowOption"
										placeholder="Deje comentario aquí ..."
									/>
								</b-form-group>
							</b-col>
						</b-row>

						<template #footer>
							<b-col class="d-flex flex-column flex-sm-row justify-content-sm-end">
								<!-- BUTTON SUBMIT -->
								<!-- <b-button-loading
									text="GUARDAR CAMBIOS"
									type="submit"
									class="order-sm-2 order-1 ml-sm-2 ml-0"
									:processing="processing"
								/>-->

								<b-button
									v-ripple.400="'rgba(186, 191, 199, 0.15)'"
									type="button"
									variant="outline-secondary"
									class="ml-2"
									@click="refCommentModal.hide()"
								>CERRAR</b-button>
							</b-col>
						</template>
					</b-card>
				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import useNotifications from "@notifications"
import { formatStringToDate, formatCurrency } from "@core/utils/filter"
import { ref, onBeforeMount, onUnmounted } from "@vue/composition-api"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import CriticalitiesListFilters from "./PerformancesListFilters.vue"
import performanceStoreModule from "../performanceStoreModule"
import useCriticalitiesList from "./usePerformancesList"
import Ripple from "vue-ripple-directive"
import { quillEditor } from "vue-quill-editor"
import "quill/dist/quill.core.css"
import "quill/dist/quill.snow.css"
import "quill/dist/quill.bubble.css"
import { avatarText } from "@core/utils/filter"
import VueSweetalert2 from "vue-sweetalert2"
import vSelect from "vue-select"
import store from "@/store"
import axios from "@axios"
import Vue from "vue"
import "animate.css"

Vue.use(VueSweetalert2)

export default {
	components: {
		ValidationProvider,
		ValidationObserver,
		CriticalitiesListFilters,
		vSelect,
		quillEditor
	},
	directives: {
		Ripple
	},
	setup() {
		// USE TOAST
		const { swalNotification, toastNotification } = useNotifications()

		// REFS
		const editorHeight = ref(150)
		const options = ref({})
		const refCommentForm = ref(null)
		const refCommentModal = ref(null)
		const isOptionsLoaded = ref(false)
		const processing = ref(false)
		const snowOption = ref({
			theme: "snow"
		})
		const formData = ref({
			security_comment: null,
			productivity_comment: null,
			labor_comment: null,
			colpa_comment: null
		})

		const PERFORMANCE_APP_STORE_MODULE_NAME = "app-performance"

		// REGISTER MODULE
		if (!store.hasModule(PERFORMANCE_APP_STORE_MODULE_NAME))
			store.registerModule(
				PERFORMANCE_APP_STORE_MODULE_NAME,
				performanceStoreModule
			)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(PERFORMANCE_APP_STORE_MODULE_NAME))
				store.unregisterModule(PERFORMANCE_APP_STORE_MODULE_NAME)
		})

		// CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
		onBeforeMount(() => {
			getSelectFilters()
		})

		const getSelectFilters = async () => {
			try {
				const [levels, states, months] = await Promise.all([
					axios.get("/selectors/criticality-levels"),
					axios.get("/selectors/states"),
					axios.get("/selectors/months")
				])
				options.value = {
					...levels.data,
					...states.data,
					...months.data
				}
				isOptionsLoaded.value = true
			} catch (error) {
				toastNotification("Error al cargar datos de selección", false)
			}
		}

		const saveCommentModal = async () => {
			console.log(formData.value)
		}

		const openCommentModal = () => {
			refCommentModal.value.show()
		}

		const {
			fetchPerformances,
			tableColumns,
			perPage,
			currentPage,
			totalPerformances,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refPerformanceListTable,
			refetchData,

			// EXTRA FILTERS
			statesFilter,
			criticalityLevelsFilter,
			monthFilter,
			yearFilter
		} = useCriticalitiesList()

		return {
			options,
			isOptionsLoaded,

			fetchPerformances,
			tableColumns,
			perPage,
			currentPage,
			totalPerformances,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refPerformanceListTable,
			refetchData,
			snowOption,
			formData,
			processing,
			refCommentForm,
			refCommentModal,
			editorHeight,

			// EXTRA FILTERS
			statesFilter,
			criticalityLevelsFilter,
			monthFilter,
			yearFilter,

			// ACTIONS
			avatarText,

			formatStringToDate,
			formatCurrency,
			saveCommentModal,
			openCommentModal
		}
	}
}
</script>

<style lang="scss" scoped>
.media {
	align-items: center;
}

.per-page-selector {
	width: 90px;
}

.ql-container {
	height: 150px !important;
}
</style>

<style>
.ql-container {
	height: 150px !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>